@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap");

$default-bgcolor: #212121;
$default-color: #d7d7db;
$bg-card: #262626;
$theme-color: rgba(255, 255, 255, 0.8);
$theme-color-hover: rgba(255, 255, 255, 0.8);
$primary-gradient: linear-gradient(#d3145acc, #d3145acc);
$text-color-button: #212833;

body {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 12px;
  background-color: $default-bgcolor;
  color: $default-color;
}

a {
  color: $theme-color;
}

a:hover {
  color: $theme-color-hover;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $default-color;
}

.pointer {
  cursor: pointer;
}

header {
  z-index: 12;
  height: 40px;

  box-shadow: inset 0 -1px 0 #3b3b3b;
  width: 100%;
  align-items: center;
  padding: 0 30px;
  text-transform: capitalize;
  position: fixed;
  margin-bottom: 3px;
  background: $bg-card;
  justify-content: space-between;

  z-index: 1000;
}

header .text-site-name {
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  width: 250px;
}

header .logo {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-right: auto; */
  cursor: pointer;
  margin-left: -38px;
}

header .logo img {
  width: 120px;
}

.save {
  margin-right: 8px;
}

/*BURGER BTN*/
.burger {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 3px 0;
  width: 18px;
  height: 18px;
  position: relative;
  cursor: pointer;
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  margin-left: -30px;
  margin-right: 5px;
}

.burger span {
  height: 2px;
  width: 100%;
  background: $theme-color;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.header-right-panel {
  display: flex;
  // margin-right: -30px;
  align-items: center;
}

.side_bar_wrapper {
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  height: 100%;
  font-size: 16px;
}

.side_bar_wrapper .sidebar {
  background: transparent;
}

@media (min-width: 992px) {
  .side_bar_wrapper {
    display: none;
  }
}

.side_bar_wrapper .side_bar {
  height: 100%;
  width: 0;
  position: absolute;
  top: 0;
  -webkit-transition: 0.22s ease-out;
  transition: 0.22s ease-out;
  padding: 15px 0 30px;
  left: -500px;
  -webkit-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.25);
  background: $bg-card;
  z-index: 1001;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  overflow-y: auto;
  overflow-x: hidden;
}

.side_bar_wrapper .side_bar .social-icons {
  text-align: center;
  width: 100%;
  margin-top: 15px;
}

@media (min-width: 991px) {
  .side_bar_wrapper .side_bar .social-icons {
    display: none;
  }
}

.side_bar_wrapper .side_bar .social-icons a .telegram {
  -webkit-mask-size: cover;
  mask-size: cover;
}

.side_bar_wrapper .side_bar .social-icons a span {
  display: inline-block;
  width: 30px;
  height: 30px;
  background: #20c5cb;
}

.side_bar_wrapper .plash {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.25);
  z-index: 999;
}

.side_bar_wrapper .close_bar {
  z-index: 1100;
  background: $bg-card;
  position: absolute;
  top: 0;
  left: -20px;
  width: 62px;
  height: 62px;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-shadow: 3px 0 5px 0 rgba(0, 0, 0, 0.18);
  box-shadow: 3px 0 5px 0 rgba(0, 0, 0, 0.18);
  opacity: 0;
  -webkit-transition: 0.22s ease-out;
  transition: 0.22s ease-out;
  -webkit-transition-property: left, opacity;
  transition-property: left, opacity;
  cursor: pointer;
}

.side_bar_wrapper .close_bar:after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: 3px;
  -webkit-box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.side_bar_wrapper.show {
  visibility: visible;
}

.side_bar_wrapper.show .side_bar {
  left: 0;
  width: 320px;
  min-width: 320px;
  max-width: calc(100vw - 62px);
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.side_bar_wrapper.show .close_bar {
  left: 320px;
  opacity: 1;
  cursor: pointer;
}

.side_bar_wrapper .close_bar i {
  color: $theme-color;
}

header .side_bar_wrapper .logo {
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 30px;
}

header .side_bar_wrapper .logo .logo-img {
  display: inline-block;
  margin: 0 auto;
  width: 170px;
  height: 51px;
  background-size: cover;
  background-repeat: no-repeat;
}

.element-bg {
  background-color: #2c2f37;
}

.dashboard .dash-side {
  position: absolute;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  /* -webkit-box-pack: justify;
    -ms-flex-pack: justify; */
  justify-content: space-between;
}

.dashboard.openFullBar .content-wrapper,
.dashboard.openFullBar footer {
  margin-left: 200px;
}

.dashboard .content-wrapper {
  -webkit-transition: margin-left 0.2s ease;
  transition: margin-left 0.2s ease;
  min-height: calc(100vh - 88px);
}

.dashboard .new-line-on-dashboard {
  margin-top: -35px;
  margin-left: -22px;
  margin-right: -25px;
  margin-bottom: 20px;
}

.sidebar {
  background: $bg-card;
  padding-top: 15px;
  min-height: calc(100vh - 60px);
  width: 200px;
  -webkit-transition: width, 0.2s ease;
  transition: width, 0.2s ease;
  -webkit-box-shadow: 2px 5px 5px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 2px 5px 5px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 100;
}

.sidebar ul {
  margin: 0;
  list-style-type: none;
  padding-left: 0;
}

/* .sidebar ul li a {
  color: #fff;
  text-decoration: none;
} */

.sidebar .nav-link {
  text-align: left;
  padding-left: 25px;
  margin-bottom: 3px;
  border: 1px solid transparent;
}

.sidebar .nav-link i {
  margin-right: 7px;
}

.top-path {
}

.top-path ul.top-path-menu {
}

.top-path ul.top-path-menu li {
  line-height: 2.5;
  color: #ffaaaa;
}

.top-path ul.top-path-menu li:hover {
  background-color: #212121;
}

a.tp-logout {
  color: #fd1505;
}

/* .sidebar .nav-link.exact-active,
.sidebar .nav-link:not(.disabled-link):hover {
  border: 1px solid #23252b;
  background: #23252b;
  color: #fff;
} */

.sidebar .nav-link.disabled-link {
  opacity: 0.7;
  cursor: not-allowed;
}

.sidebar .separ-line {
  height: 1px;
  background: #dcdfe6;
  margin: 20px 25px;
}

// .sidebar .bottom-path {
//   padding: 0 15px;
// }

// .sidebar .bottom-path .link-btn {
//   display: flex;
//   -webkit-box-align: center;
//   -ms-flex-align: center;
//   align-items: center;
//   -webkit-box-pack: justify;
//   -ms-flex-pack: justify;
//   justify-content: space-between;
//   padding: 10px 11px;
//   margin-bottom: 6px;
//   background: $default-bgcolor;
//   white-space: nowrap;
//   cursor: pointer;
//   text-decoration: none;
//   color: $default-color;
// }

// .sidebar .bottom-path .link-btn {
//   white-space: nowrap;
//   cursor: pointer;
//   color: $default-color;
// }

.help_btn {
  width: 130px;
  height: 32px;
  background: $primary-gradient;
  border-radius: 85px;
  padding: 0 12px;
  font-size: 1rem;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: $default-color;
  text-decoration: none;
}

.help_btn svg path {
  fill: $text-color-button;
}

.social-links-list {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 1.4rem;
}

// Contents Wrapper
.content-wrapper {
  padding-top: 35px;
  padding-bottom: 35px;
  padding-left: 25px;
  padding-right: 25px;
}

.title-page {
  font-size: 2.2rem;
  margin-bottom: 25px;
  text-align: center;
  font-weight: 600;
}

.cb_card {
  border-radius: var(--border-radius);
  background-color: $bg-card;
  color: $default-color;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 25px;
  padding: 20px 30px;
}

.add-sub-account-block {
  width: 100%;
}

.cb_card .cb_card-body,
.cb_card .cb_card-footer,
.cb_card .cb_card-header {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.add-sub-account-block .cb_card-body {
  height: 100%;
}

/* NEWS LINE*/
.new-line {
  display: flex;
  background: $bg-card;
  padding: 12px 0;
}

.new-line .nes-line_container_carousel {
  display: flex;
  width: calc(100% - 115px);
  padding-right: 20px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.new-line .nes-line_title {
  padding-left: 50px;
  padding-right: 20px;
  color: #8b9aac;
}

.new-line .nes-line_container_carousel .nes-line_container_carousel_tiny {
  width: 100%;
  overflow: hidden;
}

#tns1 {
  width: calc(700%);
  transition-duration: 0.3s;
}

#tns1 {
  font-size: 0px;
}

.tns-horizontal.tns-subpixel {
  white-space: nowrap;
}

.tns-slider {
  -webkit-transition: all 0s;
  transition: all 0s;
  transition-duration: 0s;
}

#tns1 > .tns-item {
  width: calc(14.2857%);
}

#tns1 > .tns-item {
  font-size: 12px;
}

.tns-horizontal.tns-subpixel > .tns-item {
  display: inline-block;
  vertical-align: top;
  white-space: normal;
}

.tns-slider > .tns-item {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

/* Broadcasting Container */
.broadcasting-container {
  display: flex;
  -ms-flex-line-pack: center;
  align-content: center;
  padding: 0;
  position: relative;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.broadcasting-container .title-block {
  display: flex;
  height: 59px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 400;
  font-size: 1rem;
  background: $bg-card;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  margin-bottom: 3px;
  z-index: 1;
  margin-right: 3px;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.title-icon {
  width: 75px;
}

.title-text {
  width: 125px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

svg {
  overflow: hidden;
}

.broadcasting-container .close-broadcasting {
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  font-size: 1.4rem;
  color: $default-color;
  z-index: 2000;
  right: 0;
  left: 0;
  margin: 0 auto;
  bottom: -10px;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  cursor: pointer;
}

.ps {
  position: relative;
}

.ps {
  overflow: hidden !important;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
}

.broadcasting-container .bcd-list-container > div,
.broadcasting-container .bcd-list-container {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.broadcasting-container .bcd-list-container {
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  z-index: -1;
}

.bcd-card {
  height: 59px;
  width: 245px;
  padding: 10px 20px;
  margin-right: 3px;
  margin-bottom: 3px;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background: $bg-card;
  font-size: 12px;
  -webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
}

.bcd-card .bcd-card_row {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.bcd-card .fin-lottos {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}

.bcd-card .bcd-card_row > div:first-child {
  max-width: 65%;
}

/* .bcd-card {
    font-size: .85rem;
} */

/* */
.mobile-nav-body {
  background-color: $bg-card;

  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1;
  margin-top: 0px;
  top: 0px;
  left: 0px;
  bottom: 0px;
  overflow: hidden !important;
  right: 0px;
  z-index: 9000;
  opacity: 0;
  visibility: hidden;
  -ms-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.mobile-nav-body.active {
  left: 0;
  opacity: 1;
  visibility: visible;
}

.mobile-nav-wrapper {
  width: 270px;
  height: 100%;
  position: fixed;
  left: -270px;
  top: 0;
  z-index: 10000;
  background-color: $bg-card;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  -ms-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.mobile-nav-wrapper.active {
  left: 0;
  opacity: 1;
  visibility: visible;
}

.mobile-nav-wrapper .close_bar {
  z-index: 10000;
  background-color: $bg-card;
  position: absolute;
  top: 0;
  left: -20px;
  width: 62px;
  height: 62px;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-shadow: 3px 0 5px 0 rgba(0, 0, 0, 0.18);
  box-shadow: 3px 0 5px 0 rgba(0, 0, 0, 0.18);
  -webkit-transition: 0.22s ease-out;
  transition: 0.22s ease-out;
  transition-property: all;
  -webkit-transition-property: left, opacity;
  transition-property: left, opacity;
  left: 270px;
}

.mobile-nav-wrapper .close_bar i {
  color: $theme-color;
  font-weight: 600;
  background: #1e2027;
  padding: 0.5rem;
  border-radius: 50%;
}

.mobile-nav-wrapper .close_bar:hover i {
  color: $theme-color-hover;
}

.mobile-nav-wrapper .logo {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mobile-nav-wrapper .logo img {
  width: 120px;
}

.mobile-sidebar {
  padding-top: 35px;
  min-height: calc(100vh - 60px);
  -webkit-transition: width, 0.2s ease;
  transition: width, 0.2s ease;
  -webkit-box-shadow: 2px 5px 5px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 2px 5px 5px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 100;
}

.mobile-sidebar ul {
  margin: 0;
  list-style-type: none;
  padding-left: 0;
}

.mobile-sidebar ul li a {
  color: #fff;
  text-decoration: none;
}

.mobile-sidebar .nav-link {
  text-align: left;
  padding-left: 25px;
  margin-bottom: 3px;
  border: 1px solid transparent;
}

.mobile-sidebar .nav-link i {
  margin-right: 7px;
}

.mobile-sidebar .nav-link.exact-active,
.mobile-sidebar .nav-link:not(.disabled-link):hover {
  border: 1px solid #23252b;
  background: #23252b;
  color: #fff;
}

.mobile-sidebar .nav-link.disabled-link {
  opacity: 0.7;
  cursor: not-allowed;
}

.mobile-sidebar .separ-line {
  height: 1px;
  background: #dcdfe6;
  margin: 20px 25px;
}

.read_more {
  margin-left: 10px;
}

.ant-menu {
  font-size: 12px;
  background: #262626;
  color: #bdbdbd;
}

.ant-menu-item-group-title {
  height: 1.5715;
  padding: 8px 16px;
  padding-left: 16px;
  color: #bdbdbd;
  font-size: 12px;
  line-height: 1.5715;
  transition: all 0.3s;
}

.ant-menu-submenu-selected {
  color: #fff;
}

.ant-menu-sub.ant-menu-inline {
  background: #262626;
}

.ant-menu-submenu > .ant-menu {
  background: #262626;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 0;
}

.ant-menu-light .ant-menu-submenu-title:hover {
  color: #fff;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #212121;
}

.ant-menu-item-selected {
  color: #fff;
}

.ant-menu-light .ant-menu-submenu-active {
  color: #fff;
}
