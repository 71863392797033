@media only screen and (max-width: 1540px) {
}

@media only screen and (max-width: 1450px) {
}

@media only screen and (min-width: 1199px) {
}

@media only screen and (max-width: 1199px) {
}

@media only screen and (max-width: 1024px) {
}

@media only screen and (max-width: 991px) {
  .dashboard .content-wrapper,
  .dashboard footer {
    margin: 0 !important;
  }

  .broadcasting-container .title-block {
    display: none;
  }

  .dashboard .new-line-on-dashboard {
    margin-top: -20px;
  }

  header .logo {
    margin-left: 0px !important;
  }

  .new-line .nes-line_title {
    padding-left: 10px;
    padding-right: 10px;
    color: #8b9aac;
  }

  .new-line .nes-line_container_carousel {
    width: calc(100% - 20px);
  }
}

@media only screen and (min-width: 991px) {
  .burger {
    display: none;
  }
}

@media only screen and (min-width: 767px) {
}

@media only screen and (max-width: 767px) {
  .cb_card {
    padding: 10px 15px;
  }
}

@media only screen and (max-width: 575px) {
}

@media only screen and (max-width: 480px) {
}

@media (max-width: 540px) {
  .new-line .nes-line_container_carousel {
    padding-right: 0;
  }
}
